import { IRoleID } from './roles';

export enum EAdminStatus {
  INACTIVE,
  ACTIVE
}

export interface IAdmins {
  items: IAdminID[];
  count: number;
}

export interface IAdminID {
  id: string;
  username: string;
  firstname: string;
  email: string;
  roles: IRoleID[];
  status: EAdminStatus;
}


