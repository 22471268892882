import React, { useRef, useState } from 'react';

import Table from '../../components/Table';
import ButtonSettings from '../../components/ButtonSettings';
import { useQuery } from 'react-query';
import { addBalanceCustomer, getListCustomers } from '../../stores/customers';
import { ICustomer, ICustomers } from '../../types/customers';
import { showProfileCustomer } from '../../stores/modalContainer';
import Status from '../../components/status/Status';
import CustomerFilter from '../../components/filter/CustomerFilter';
import SelectorActions from '../../components/selectors/SelectorActions';
import { useFilter } from '../../stores/filter';
import Loader from '../../components/Loader';
import TextareaField from '../../components/form/TextareaField';
import AsyncButton from '../../components/AsyncButton';
import InputField from '../../components/form/InputField';

const Customers = () => {
  const [ modal, setModal ] = useState<null | ICustomer>( null );
  const filter = useFilter();

  const textarea = useRef( null );
  const input = useRef( null );

  const {
    data: customers,
    isLoading,
    refetch
  } = useQuery<ICustomers>( [ 'customers', filter.toString() ], () => getListCustomers( filter ) );


  const addBalance = async () => {
    if ( !modal?.id ) return;
    await addBalanceCustomer( modal?.id, {
      //@ts-ignore
      amount: Number( input.current?.value ),
      //@ts-ignore
      comment: textarea.current?.value
    } );
    refetch();
    setModal( null );
  };

  return (
    <div className='users'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Customers</div>
        <CustomerFilter/>
        <SelectorActions/>
        { isLoading ?
          <Loader position={ 'static' }/>

          :
          <Table
            items={ customers?.items || [] }
            count={ customers?.count }
            fields={ {
              username: 'Customer name',
              email: 'Email',
              telephone: 'Mobile',
              balance: 'Balance',
              created_at: 'Signup day',
              status: 'Status',
              actions: 'Actions'
            } }
            headFieldsClassName={ {
              status: 'thText-center',
              actions: 'thText-center'
            } }
            bodyFieldsClassName={ {
              status: 'thText-center',
              actions: 'thText-center'
            } }
            columnBody={ {
              created_at: ( item ) =>
                <div>
                  {
                    item.created_at.substring( 0, 10 )
                  }
                </div>,
              firstname: ( item ) =>
                <button onClick={ () => showProfileCustomer( item.id ) } className='linkMassage'>
                  { item.firstname }
                </button>,
              balance: ( item ) =>
                <div>
                  <div className='amount'>{ item.balance }</div>
                  <button className='btnAddbalance mb-5' onClick={ () => setModal( item ) }>Add balance</button>
                </div>,
              status: ( item ) => <Status status={ item.status }/>,
              actions: ( item ) =>
                <div className='padding5'>
                  <ButtonSettings itemId={ item.id }/>
                </div>
            } }
          />
        }
        {/*<Notes/>*/ }
      </div>

      { modal &&
        <div className="reject-modal modal-wrapper">
          <div className="modal-overlay" onClick={ () => setModal( null ) }/>
          <div className="reject-body modal-body" style={ { width: 600, maxWidth: '100%' } }>
            <InputField label="Amount" inputRef={ input }/>
            <TextareaField inputRef={ textarea } label="Comment" containerClassName="mb-20"/>
            <div className="d-flex justify-end">
              <AsyncButton
                className="btnEdit"
                onClick={ addBalance }
              >Add</AsyncButton>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Customers;
