import React from 'react';
import '../../styles/editAdminGroups.scss';
import { createAdminGroup } from '../../stores/roles';
import RoleForm from './RoleForm';
import { useNavigate } from 'react-router-dom';


const CreateAdminGroups = () => {
  const navigate = useNavigate();
  const create = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    await createAdminGroup( data );

    navigate( '/admin/list' );
  };

  return (
    <RoleForm
      save={ create }
      label="Create Admin Groups"
    />
  );
};

export default CreateAdminGroups;
