import React from 'react';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IClientsStat } from '../../types/statistic';
import { momentjs } from '../../utils/date';
import { ECustomerRole } from '../../types/customers';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  scales: {
    y: {
      ticks: {
        min: 0.00,
        max: 200.00,
        stepSize: 40.00,
      },
      title: {
        display: true,
        text: 'Total',
        color: 'black',
        font: {
          weight: 'bold',
          size: 14
        }
      }
    },
    x: {
      grid: {
        display: false
      },
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
      labels: {
        padding: 20,
        usePointStyle: true,
        pointStyle: 'rect',
        font: {
          weight: 'bold',
          size: 12
        }
      }
    },
  }
};

interface IProps {
  clients: IClientsStat[];
}

const CustomersQuestionnaires = ( { clients }: IProps ) => {
  const data = {
    labels: clients.map( o => momentjs( o.date ).format( 'MMM' ) ),
    datasets: [
      {
        label: 'Customer',
        data: clients.map( o => o.counts.find( c => c.roleId === ECustomerRole.CLIENT )?.count || 0 ),
        backgroundColor: 'rgba(116, 106, 236)',
      },
      {
        label: 'Provider',
        data: clients.map( o => o.counts.find( c => c.roleId === ECustomerRole.MODEL )?.count || 0 ),
        backgroundColor: 'rgb(236,106,206)',
      },
    ],
  };

  return (
    <div className='parent'>
      <Bar
        data={ data }
        options={ options }
      />
    </div>
  );
};

export default CustomersQuestionnaires;
