import React from 'react';
import { Link } from 'react-router-dom';

const PaymentsRequests = () => {
  return (
    <div className='column containerContent width-100'>
      <div className='row justify-between width-90 padding20'>
        <div className='titleDashboardPage'>Payment Requests</div>
        <Link to="/support/payment_requests" className='btnView width-10'>View all</Link>
      </div>
      <div className='d-flex h3 align-center justify-center mb-50'>No Payment Requests.</div>
    </div>
  );
};

export default PaymentsRequests;
