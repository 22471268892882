import { IPriceQuestionnaire } from './prices';
import { IReviews } from './reviews';

export enum EQuestionnaireStatus {
  CREATED,
  CONFIRMED,
  REJECTED,
  MODERATE
}

export interface IQuestionnaires {
  items: IQuestioner[];
  count: number;
}

export interface IQuestioner {
  id: string;
  city: string;
  address: string;
  lat: number;
  lon: number;
  status: EQuestionnaireStatus;
  statusName: string;
  rating: number;
  name: string;
  age: number;
  gender: string;
  about: string;
  photo: string;
  video: string;
  height: number;
  weight: number;
  breastSize: number;
  verification: string;
  created_at: string;
  balance: number;
  prices: IPriceQuestionnaire[];
  photos: string[];
  schedule: string[];
}

export interface IQuestionerID extends IQuestioner {
  verificationVideo: string;
  reviews: IReviews[];
  tags: ITags[];
  categories: ICategories[];
  attributeGroups: IAttributeGroup[];
  cancelledTrips24: number;
  cancelledTripsTotal: number;
}

export interface ILanguageProperty {
  [ key: string ]: string
}


export interface ITags {
  items: ITag[];
  count: number;
}

export interface ITag {
  id: number;
  name: string;
  status: number;
}

export interface ITagID {
  id: number;
  name: ILanguageProperty; //это когда значение содержит объект с параметрами, это для языковых именно
  status: number;
}

export interface ICategories {
  items: ICategory[];
  count: number;
}

export interface ICategory {
  id: number;
  name: string;
  status: number;
}

export interface ICategoryID {
  id: number;
  name: ILanguageProperty; //это когда значение содержит объект с параметрами, это для языковых именно
  status: number;
}

export interface IAttributeGroups {
  items: IAttributeGroup[];
  count: number;
}

export interface IAttributeGroup {
  id: number;
  name: string;
  type: string;
  status: number;
}

export interface IAttributeGroupID {
  id: number;
  name: ILanguageProperty;
  type: string;
  status: number;
}

export interface IAttributes {
  items: IAttribute[];
  count: number;
}

export interface IAttribute {
  id: number;
  attributeGroupId: number;
  name: string;
  status: number;
}

export interface IAttributeID {
  id: number;
  serviceGroupId: number;
  name: ILanguageProperty;
  status: number;
}

