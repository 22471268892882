import React from 'react';
import { GoOrganization, GoPeople, GoPerson } from 'react-icons/go';
import { useQuery } from 'react-query';
import { getEntityCounts } from '../../../stores/statistic';
import Loader from '../../../components/Loader';

const MemberStat = () => {
  const { data: stat, isLoading } = useQuery( 'member_stat', getEntityCounts );

  if ( isLoading ) {
    return <Loader position={ 'static' }/>;
  }

  return (
    <div className='column width-95 padding20'>
      <div className='titleDashboardPage mb-30'>Member Statistics</div>
      <div className='row justify-between width-85'>
        <div className='row align-center justify-center mb-30'>
          <div className='circleDiv grey d-flex mr-10'>
            <GoPeople className='iconCircle dark-grey'/>
          </div>
          <div className='column'>
            <div className='text-up'>{ stat?.usersCount || 0 }</div>
            <div className='text-down'>Users</div>
          </div>
        </div>
        <div className='row align-center justify-center mb-30'>
          <div className='circleDiv blue d-flex mr-10'>
            <GoPerson className='iconCircle dark-blue'/>
          </div>
          <div className='column'>
            <div className='text-up'>{ stat?.questionnairesCount || 0 }</div>
            <div className='text-down'>Questionnaires</div>
          </div>
        </div>
        <div className='row align-center justify-center mb-30'>
          <div className='circleDiv orange d-flex mr-10'>
            <GoOrganization className='iconCircle dark-orange'/>
          </div>
          <div className='column'>
            <div className='text-up'>{ stat?.brothelsCount || 0 }</div>
            <div className='text-down'>Brothels</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberStat;
