import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import { useQuery } from 'react-query';
import { IAdminID } from '../../types/admins';
import { getAdminID, saveAdmin } from '../../stores/admins';
import AdminForm from './AdminForm';

const EditAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: adminID, isLoading } = useQuery<IAdminID>( [ 'adminID' ], () => getAdminID( id ) );
  if ( isLoading ) return <Loader/>;

  const save = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    if ( id ) await saveAdmin( id, data );

    navigate( '/admin/list' );
  };

  return (
    <div className='editAdmin'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit Admin Alex*****</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/admin/list' ) }>BACK TO LISTING
          </button>
        </div>
        <AdminForm item={ adminID } save={ save }/>
      </div>
    </div>
  );
};

export default EditAdmin;
