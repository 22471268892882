import React, { useState } from 'react';
import OrdersStatistic from '../../../components/charts/OrdersStatistic';
import { useQuery } from 'react-query';
import { getOrdersStat } from '../../../stores/statistic';
import Loader from '../../../components/Loader';
import Select from 'react-select';
import { yearsList } from '../../../stores/data';

const OrdersStat = () => {
  const [ selectedOption, setSelectedOption ] = useState( yearsList[ 0 ] );
  const change = ( value: any ) => {
    setSelectedOption( value );
  };

  const {
    data: orders,
    isLoading
  } = useQuery( [ 'orders_stat', selectedOption.value ], () => getOrdersStat( selectedOption.value ) );


  return (
    <div className='column containerContent padding20 width-46'>
      { isLoading ?
        <Loader position={ 'static' }/>
        :
        <>
          <div className='row justify-between width-100 mb-30'>
            <div className='titleDashboardPage'>Order Statistic</div>
            <div className='row justify-between align-center width-23'>
              <div className='text-in-Title'>Year</div>
              <Select
                className='selector-small width-70'
                defaultValue={ selectedOption }
                onChange={ change }
                options={ yearsList }
              />
            </div>
          </div>
          <div>
            <OrdersStatistic orders={ orders || [] }/>
          </div>
        </>
      }
    </div>
  );
};

export default OrdersStat;
