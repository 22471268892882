import React from 'react';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { IOrdersInStatus } from '../../types/statistic';
import { EOrderStatus } from '../../types/orders';

ChartJS.register( ArcElement, Tooltip, Legend );

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right' as const,
      labels: {
        padding: 20,
        usePointStyle: true,
        pointStyle: 'circle',
        font: {
          weight: 'bold',
          size: 12
        }
      }
    },
  }
};

interface IProps {
  orders?: IOrdersInStatus[];
}

const TotalOrdersCircleChart = ( { orders }: IProps ) => {
  const data = {
    labels: [ 'Cancelled Orders', 'Completed Orders', 'On Going Orders' ],
    datasets: [
      {
        label: '',
        data: [
          orders?.find( o => o.status === EOrderStatus.REFUNDED )?.status || 0,
          orders?.find( o => o.status === EOrderStatus.EXECUTED )?.status || 0,
          orders?.find( o => o.status === EOrderStatus.PAID )?.status || 0
        ],
        backgroundColor: [
          '#e75656',
          '#28c76f',
          '#e7a612',
        ],
        borderColor: [
          '#e75656',
          '#28c76f',
          '#e7a612',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className='parent'>
      <Doughnut data={ data } options={ options }/>
    </div>
  );
};

export default TotalOrdersCircleChart;
