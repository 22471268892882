import React, { ReactNode } from 'react';
import { $permissions } from '../../stores/permissions';
import { useStore } from 'effector-react';


interface IAccessProps {
  permission?: string | string[];
  group?: string;
  children?: ReactNode;
  action?: string;
}

const Access = ( { permission, group, children, action }: IAccessProps ) => {
  const permissions = useStore( $permissions );

  if ( !permission && permissions && group && action && !permissions.get( group, action ) ) return <></>;
  if ( permission && !permissions?.getIn( permission ) ) return <></>;

  return (
    <>
      { children }
    </>
  );
};

export default Access;
