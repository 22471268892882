import React, { useRef } from 'react';
import CustomForm from '../../components/form/CustomForm';
import ButtonsCustomForm from '../../components/form/ButtonsCustomForm';
import { IQuestionerID } from '../../types/questionnaires';

interface IProps {
  save: ( form?: HTMLFormElement ) => Promise<any>;
  item?: IQuestionerID;
}

const QuestionnaireForm = ( { item, save }: IProps ) => {
  const form = useRef<HTMLFormElement>( null );

  const onClick = async () => {
    await save( form.current || undefined );
  };

  return (
    <form ref={ form }>
      <CustomForm
        key={ item?.id }
        item={ item }
        fields={ {
          photo: {
            name: 'Profile Picture',
            type: 'file',
            props: {
              containerClassName: 'width-50 mb-20',
              required: true
            }
          },
          name: {
            name: 'Name',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          rating: {
            name: 'Rating',
            type: 'input',
            props: {
              containerClassName: 'width-50'
            }
          },
          gender: {
            name: 'Gender',
            type: 'gender',
            props: {
              containerClassName: 'width-50',
            }
          },
          age: {
            name: 'Age',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          about: {
            name: 'About',
            type: 'textarea',
            props: {
              containerClassName: 'width-50 mb-20',
            }
          },
          height: {
            name: 'Height',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          weight: {
            name: 'Weight',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          breastSize: {
            name: 'Breast',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          photos: {
            name: 'Photos',
            type: 'file',
            props: {
              containerClassName: 'width-50 mb-20',
              isMulti: true,
            }
          },
          video: {
            name: 'Video',
            type: 'file',
            props: {
              containerClassName: 'width-50 mb-20',
            }
          },
          address: {
            name: 'Address',
            type: 'input',
            props: {
              containerClassName: 'width-50',
            }
          },
          verificationVideo: {
            name: 'Verification Video',
            type: 'file',
            props: {
              containerClassName: 'width-50 mb-20',
            }
          },
          status: {
            name: 'Status',
            type: 'checkbox',
            props: {
              containerClassName: 'width-50 mb-20',
            }
          },
        } }/>
      <ButtonsCustomForm onClick={ onClick } group={ 'questionnaires' }/>
    </form>
  );
};

export default QuestionnaireForm;
