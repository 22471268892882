import React from 'react';
import TotalOrdersChart from '../../../components/charts/TotalOrdersChart';
import { useQuery } from 'react-query';
import { getOrdersCountInMonth } from '../../../stores/statistic';

import Loader from '../../../components/Loader';

const OrdersCountInMonth = () => {
  const { data: orders, isLoading } = useQuery( 'orders_count', getOrdersCountInMonth );

  if ( isLoading ) {
    return <Loader position={ 'static' }/>;
  }

  return (
    <div className='column width-85 padding20'>
      <div className='titleDashboardPage'>Total Orders</div>
      <div className='subTitleDashboard mt-10 mb-10'>{ orders?.ordersCount || 0 }</div>
      <div>
        <TotalOrdersChart orders={ orders }/>
      </div>
    </div>
  );
};

export default OrdersCountInMonth;
