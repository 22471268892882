import React, { useRef } from 'react';
import CustomForm from '../../components/form/CustomForm';
import ButtonsCustomForm from '../../components/form/ButtonsCustomForm';
import { IBrothelID } from '../../types/brothels';

interface IProps {
  save: ( form?: HTMLFormElement ) => Promise<any>;
  item?: IBrothelID;
}

const BrothelForm = ( { save, item }: IProps ) => {
  const form = useRef<HTMLFormElement>( null );

  const onClick = async () => {
    await save( form.current || undefined );
  };

  return (
    <form ref={ form }>
      <CustomForm
        key={ item?.id }
        item={ item }
        fields={ {
          name: {
            name: 'Brothel Name',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          rating: {
            name: 'Rating',
            type: 'input',
            props: {
              containerClassName: 'width-50'
            }
          },
          pass: {
            name: 'Password',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          photo: {
            name: 'Profile Picture',
            type: 'file',
            props: {
              containerClassName: 'width-50 mb-20'
            }
          },
          video: {
            name: 'Video',
            type: 'file',
            props: {
              containerClassName: 'width-50 mb-20',
            }
          },
          address: {
            name: 'Address',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          telephone: {
            name: 'Phone',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          }
        } }
      />
      <ButtonsCustomForm onClick={ onClick } group={ 'brothels' }/>
    </form>
  );
};

export default BrothelForm;
