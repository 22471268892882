import React, { RefObject } from 'react';

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  containerClassName?: string;
  label?: string;
  inputRef?: RefObject<HTMLInputElement>
}

const InputField = ( { containerClassName, label, maxLength, inputRef, ...props }: IProps ) => {
  const onKeyDown = ( e: any ) => {
    if ( maxLength && e.key != 'Backspace' && e.target.value >= maxLength ) {
      e.preventDefault();
    }
  };

  return (
    //если нужно добавить свойство элементу - создаем новый пропс
    <div className={ containerClassName }>
      <div className='boldTextEdit mb-10'>
        { label }
        {/*пропс reqiuired из стандартной типизации для input (навести на элемент, найти пропсы и перейти) - условие если пропс true - отображаем*/ }
        {/*я забыла пропс передать в форму*/ }
        { props.required && <span className='red-star'>*</span> }
      </div>
      <input
        type='text'
        ref={ inputRef }
        onKeyDown={ onKeyDown }
        maxLength={ maxLength }
        className='widthInput input mb-20'
        { ...props }
      />
    </div>
  );
};

export default InputField;
