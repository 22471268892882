import { apiGet, apiPost } from '../api';
import { FilterParams } from './filter';
import { IWithdrawRequest, IWithdrawRequests } from '../types/withdrawRequests';

export const getWithdrawRequests = async ( filter?: FilterParams ) => {
  return await apiGet<IWithdrawRequests>( { url: `/withdrawRequests?${ filter?.toQueryParams() }` } );
};

export const getWithdrawRequest = async ( id: string | number ) => {
  return await apiGet<IWithdrawRequest>( { url: `/withdrawRequests/${ id }` } );
};

export const saveWithdrawRequest = async ( id: string | number, data: any ) => {
  return await apiPost<IWithdrawRequest>( {
    url: `/withdrawRequests/${ id }`,
    postData: data
  } );
};
