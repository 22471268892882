import React from 'react';
import Table from '../../components/Table';
import { useQuery } from 'react-query';
import { deleteRole, getListRoles } from '../../stores/roles';
import { IRoles } from '../../types/roles';
import Loader from '../../components/Loader';
import Status from '../../components/status/Status';
import AdminGroupFilter from '../../components/filter/AdminGroupFilter';
import SelectorActions from '../../components/selectors/SelectorActions';
import { useFilter } from '../../stores/filter';
import ButtonSettings from '../../components/ButtonSettings';

const AdminGroups = () => {
  const filter = useFilter();

  const { data: roles, isLoading } = useQuery<IRoles>( [ 'roles', filter.toString() ], () => getListRoles( filter ) );

  return (
    <div className='adminGroups'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Admin Groups</div>
        <AdminGroupFilter/>
        <SelectorActions/>
        { isLoading ?
          <Loader position={ 'static' }/>

          :
          <Table
            items={ roles?.items || [] }
            count={ roles?.count }
            fields={ {
              name: 'Group Name',
              permissions: 'Permissions',
              status: 'Status',
              actions: 'Actions'
            } }
            bodyFieldsClassName={ {
              name: 'width-15',
              permissions: 'padding5',
              status: 'thText-center',
              actions: 'thText-center',
            } }
            headFieldsClassName={ {
              status: 'thText-center',
              actions: 'thText-center'
            } }
            columnBody={ {
              status: ( item ) => <Status status={ item.status }/>,
              actions: ( item ) =>
                <div className='padding5'>
                  <ButtonSettings
                    itemId={ item.id }
                    deleteItem={ deleteRole }
                  />
                </div>,
              permissions: ( item ) => item.permissions?.map( p => p.group + ':' + p.action ).join( ', ' )
            } }
          />
        }
        {/*<Notes/>*/ }
      </div>
    </div>
  );
};

export default AdminGroups;
