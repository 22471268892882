import React, { useState } from 'react';

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  containerClassName?: string;
  label?: string;
  isMulti?: boolean;
}

const InputTypeFileField = ( { containerClassName, label, defaultValue, isMulti, name, ...props }: IProps ) => {
  const [ value, setValue ] = useState( defaultValue );
  return (
    <div className={ containerClassName }>
      <div className='boldTextEdit mb-10'>
        { label }
        { props.required && <span className='red-star'>*</span> }
      </div>
      <div>
        <input type='file' className='widthInput input' { ...props } name={ !isMulti ? name : name + '[]' }
               multiple={ isMulti }/>
      </div>
    </div>
  );
};

export default InputTypeFileField;
