import {
  EQuestionnaireStatus,
  IAttributeGroupID,
  IAttributeGroups,
  IAttributeID,
  IAttributes,
  ICategories,
  ICategoryID,
  IQuestionerID,
  IQuestionnaires,
  ITagID,
  ITags
} from '../types/questionnaires';
import { apiGet, apiPost } from '../api';
import { createEvent, createStore } from 'effector';
import { FilterParams } from './filter';
import { IBrothelID } from '../types/brothels';

export const getListQuestionnaires = async ( filter?: FilterParams ) => {
  return await apiGet<IQuestionnaires>( { url: `/questionnaires?${ filter?.toQueryParams() }` } ); //toQueryParams превращает объект в строку, вопросик разделяет адрес и динамическую часть адреса
};

export const getQuestionerID = async ( id?: string | null | number ) => {
  return await apiGet<IQuestionerID>( { url: `/questionnaires/${ id }`, } );
};

export const saveQuestionnaire = async ( id: string, data: any ) => {
  return await apiPost<IQuestionerID>( {
    url: `/questionnaires/${ id }`,
    postData: data
  } );
};

export const createQuestionnaire = async ( data: any ) => {
  return await apiPost<IQuestionerID>( {
    url: '/questionnaires',
    postData: data
  } );
};

export const activateQuestionnaire = async ( id: string | number ) => {
  return await apiPost<IBrothelID>( {
    url: `/questionnaires/${ id }`,
    postData: {
      status: EQuestionnaireStatus.CONFIRMED,
    }
  } );
};

export const deactivateQuestionnaire = async ( id: string | number ) => {
  return await apiPost<IBrothelID>( {
    url: `/questionnaires/${ id }`,
    postData: {
      status: EQuestionnaireStatus.REJECTED,
    }
  } );
};

// export const updateQuestionnaires = createEvent<IQuestioner[]>();
//
// export const $questionnaires = createStore<IQuestioner[]>( [] )
// 		.on( updateQuestionnaires, ( s, p ) => p );


export const getTagsList = async ( filter?: FilterParams ) => {
  return await apiGet<ITags>( { url: `/questionnaires/tags?${ filter?.toQueryParams() }` } );
};

export const getTagID = async ( id?: string ) => {
  return await apiGet<ITagID>( { url: `/questionnaires/tags/${ id }`, } );
};

export const saveTags = async ( id: string, data: any ) => {
  return await apiPost<ITagID>( {
    url: `/questionnaires/tags/${ id }`,
    postData: data
  } );
};

export const createTags = async ( data: any ) => {
  return await apiPost<ITagID>( {
    url: '/questionnaires/tags',
    postData: data
  } );
};

export const updateTags = createEvent<ITagID[]>();

export const $tags = createStore<ITagID[]>( [] )
  .on( updateTags, ( s, p ) => p );

export const getCategoriesList = async ( filter?: FilterParams ) => {
  return await apiGet<ICategories>( { url: `/questionnaires/categories?${ filter?.toQueryParams() }` } );
};

export const getCategory = async ( id?: string ) => {
  return await apiGet<ICategoryID>( { url: `/questionnaires/categories/${ id } ` } );
};

export const saveCategory = async ( id: string, data: any ) => {
  return await apiPost<ICategoryID>( {
    url: `/questionnaires/categories/${ id }`,
    postData: data
  } );
};

export const createCategory = async ( data: any ) => {
  return await apiPost<ICategoryID>( {
    url: '/questionnaires/categories',
    postData: data
  } );
};

export const updateCategory = createEvent<ICategoryID[]>();

export const $category = createStore<ICategoryID[]>( [] )
  .on( updateCategory, ( s, p ) => p );

export const getAttributeGroupsList = async ( filter?: FilterParams ) => {
  return await apiGet<IAttributeGroups>( { url: `/questionnaires/attributeGroups?${ filter?.toQueryParams() }` } );
};

export const getAttributeGroup = async ( id?: string ) => {
  return await apiGet<IAttributeGroupID>( { url: `/questionnaires/attributeGroups/${ id }` } );
};

export const saveAttributeGroup = async ( id: string, data: any ) => {
  return await apiPost<IAttributeGroupID>( {
    url: `/questionnaires/attributeGroups/${ id }`,
    postData: data
  } );
};

export const createAttributeGroup = async ( data: any ) => {
  return await apiPost<IAttributeGroupID>( {
    url: '/questionnaires/attributeGroups',
    postData: data
  } );
};

export const updateAttributeGroup = createEvent<IAttributeGroupID[]>();

export const $attributeGroup = createStore<IAttributeGroupID[]>( [] )
  .on( updateAttributeGroup, ( s, p ) => p );

export const getAttributeList = async ( filter?: FilterParams ) => {
  return await apiGet<IAttributes>( { url: `/questionnaires/attributes?${ filter?.toQueryParams() }` } );
};

export const getAttributeID = async ( id?: string ) => {
  return await apiGet<IAttributeID>( { url: `/questionnaires/attributes/${ id }` } );
};

export const saveAttributes = async ( id: string, data: any ) => {
  return await apiPost<IAttributeID>( {
    url: `/questionnaires/attributes/${ id }`,
    postData: data
  } );
};

export const createAttributes = async ( data: any ) => {
  return await apiPost<IAttributeID>( {
    url: '/questionnaires/attributes',
    postData: data
  } );
};

export const updateAttributes = createEvent<IAttributeID[]>();

export const $attribute = createStore<IAttributeID[]>( [] )
  .on( updateAttributes, ( s, p ) => p );
