import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createCategory } from '../../stores/questionnaires';
import CategoryForm from './CategoryForm';

const CreateCategory = () => {
  const navigate = useNavigate();

  const create = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    await createCategory( data );
    navigate( '/manageServices/categories' );
  };

  return (
    <div className='editCategories'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Create Category</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/manageServices/categories' ) }>BACK TO
            LISTING
          </button>
        </div>
        <CategoryForm save={ create }/>
      </div>
    </div>
  );
};

export default CreateCategory;
