import React, { useRef, useState } from 'react';

import Table from '../components/Table';
import { useQuery } from 'react-query';
import { useFilter } from '../stores/filter';
import { getWithdrawRequests, saveWithdrawRequest } from '../stores/withdrawRequests';
import Loader from '../components/Loader';
import Moment from 'react-moment';
import WithdrawFilter from '../components/filter/WithdrawFilter';
import WithdrawStatus from '../components/selectors/WithdrawStatus';
import { EWithdrawStatus, IWithdrawRequest } from '../types/withdrawRequests';
import TextareaField from '../components/form/TextareaField';
import '../styles/modal.scss';
import AsyncButton from '../components/AsyncButton';

const WithdrawRequests = () => {
  const filter = useFilter();
  const [ modal, setModal ] = useState<null | any>( null );
  const textarea = useRef( null );
  const {
    data: items,
    isLoading,
    refetch
  } = useQuery( [ 'withdrawRequests', filter.toString() ], () => getWithdrawRequests( filter ) );

  const changeWithdraw = async ( id: number, status: EWithdrawStatus ) => {
    await saveWithdrawRequest( id, {
      status: status,
      //@ts-ignore
      rejectComment: textarea.current?.value
    } );
    refetch();
    setModal( null );
  };

  const openModal = ( item: IWithdrawRequest, status: EWithdrawStatus ) => {
    setModal( {
      id: item.id,
      status: status,
    } );
  };

  const change = ( item: IWithdrawRequest, status: EWithdrawStatus ) => {
    if ( confirm( 'Attention, the action cannot be canceled! To execute?' ) ) {
      status === EWithdrawStatus.REJECTED ? openModal( item, status ) : changeWithdraw( item.id, status );
    }
  };

  return (
    <div className='withdrawRequests'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Withdraw Requests</div>
        <WithdrawFilter/>
        { isLoading ?
          <Loader/>
          :
          <Table
            items={ items?.items || [] }
            fields={ {
              amount: 'Withdraw amount',
              details: 'Contact Details',
              comment: 'Orders Details',
              balance: 'Wallet Balance',
              status: 'Status',
              date: 'Date'
            } }
            columnBody={ {
              amount: ( item ) => `$ ${ item.amount }`,
              details: ( item ) => `phone: ${ item.customer?.telephone }, email: ${ item.customer?.email }`,
              balance: ( item ) => `$ ${ item.customer?.balance }`,
              date: ( item ) => <Moment format="MMM, DD YYYY">{ item.created_at }</Moment>,
              status: ( item ) => <>
                <WithdrawStatus
                  defaultValue={ item.status }
                  onChange={ ( { value } ) => change( item, value ) }
                  disabled={ item.status !== EWithdrawStatus.CREATED }
                />
              </>,
            } }
          />
        }
        {/*<Notes/>*/ }
      </div>

      { modal &&
        <div className="reject-modal modal-wrapper">
          <div className="modal-overlay" onClick={ () => setModal( null ) }/>
          <div className="reject-body modal-body" style={ { width: 600, maxWidth: '100%' } }>
            <TextareaField inputRef={ textarea } label="Comment" containerClassName="mb-20"/>
            <div className="d-flex justify-end">
              <AsyncButton
                className="btnEdit"
                onClick={ () => changeWithdraw( modal.id, modal.status ) }
              >Reject</AsyncButton>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default WithdrawRequests;
