import React from 'react';
import { GoCheckCircleFill, GoNoEntry } from 'react-icons/go';
import { HiExclamationCircle } from 'react-icons/hi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { EQuestionnaireStatus } from '../../types/questionnaires';

interface IProps {
  status: any | boolean | string | number;
}

const StatusQueationnaire = ( { status }: IProps ) => {
  return (
    <div className='status'>
      { status === EQuestionnaireStatus.MODERATE &&
        <HiExclamationCircle className='thIconNew'/>
      }

      { status === EQuestionnaireStatus.CONFIRMED &&
        <GoCheckCircleFill className='thIconGreen'/>
      }

      { status === EQuestionnaireStatus.REJECTED &&
        <GoNoEntry className='thIconRed'/>
      }

      { status === EQuestionnaireStatus.CREATED &&
        <AiOutlineClockCircle className='thIconNew'/>
      }
    </div>
  );
};

export default StatusQueationnaire;
