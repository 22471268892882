import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../styles/editAdminGroups.scss';
import { useQuery } from 'react-query';
import { getRoleID, saveAdminGroup } from '../../stores/roles';
import Loader from '../../components/Loader';
import { IRoleID } from '../../types/roles';
import RoleForm from './RoleForm';

const EditAdminGroups = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: role, isLoading } = useQuery<IRoleID>( [ 'roleID' ], () => getRoleID( id ) );

  if ( isLoading ) return <Loader/>;
  const save = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    if ( id ) await saveAdminGroup( id, data );

    navigate( '/admin/roles' );
  };

  return (
    <RoleForm save={ save } label="Edit Admin Groups" role={ role }/>
  );
};

export default EditAdminGroups;
