import React, { useEffect, useState } from 'react';
import '../styles/verification.scss';
import { $verification, hideVerification } from '../stores/modalContainer';
import { FiXCircle } from 'react-icons/fi';
import { useStore } from 'effector-react';
import ReactPlayer from 'react-player';
import { activateQuestionnaire, deactivateQuestionnaire, getQuestionerID } from '../stores/questionnaires';
import { IQuestionerID } from '../types/questionnaires';
import { IBrothelID } from '../types/brothels';
import { activateBrothel, deactivateBrothel, getBrothelID } from '../stores/brothels';
import { staticResource } from '../utils/resources';

const Verification = () => {

  const [ state, setState ] = useState<IQuestionerID | IBrothelID>();
  const verification = useStore( $verification );

  const isQuestionnaire = verification?.type === 'questionnaire';
  const isBrothel = verification?.type === 'brothel';

  useEffect( () => {
    if ( isQuestionnaire ) getQuestionerID( verification.id ).then( setState );
    if ( isBrothel ) getBrothelID( verification.id ).then( setState );
  }, [ verification?.id ] );

  const accept = async () => {
    if ( isQuestionnaire && state?.id ) await activateQuestionnaire( state?.id );
    if ( isBrothel && state?.id ) await activateBrothel( state?.id );

    hideVerification();
  };

  const reject = async () => {
    if ( isQuestionnaire && state?.id ) await deactivateQuestionnaire( state?.id );
    if ( isBrothel && state?.id ) await deactivateBrothel( state?.id );

    hideVerification();
  };


  //react-player
  return (
    <div className='verification popup'>
      <div className='column container mb-20'>
        <div className='d-flex justify-end mb-20'>
          <button
            type='button'
            onClick={ () => hideVerification() }>
            <FiXCircle className='iconPopup'/>
          </button>
        </div>
        <div className='row justify-between mb-50'>
          <div className='width-46'>
            { state?.verification ?
              <img style={ { maxWidth: 600 } } src={ staticResource( state?.verification ) } className='profileID'/>
              :
              <h2>
                the verification photo has not been uploaded
              </h2>
            }
          </div>
          <div className='width-46'>
            { state?.verificationVideo
              ?
              <ReactPlayer controls={ true } url={ staticResource( state.verificationVideo ) }/>
              :
              <h2>
                the verification video has not been uploaded
              </h2>
            }
          </div>
        </div>
        <div className='row width-15 justify-between'>
          <button type='button' onClick={ accept } className='btnEdit btnEditGreen'>ACCEPT</button>
          <button type='button' onClick={ reject } className='btnEdit btnEditRed'>DECLINE</button>
        </div>
      </div>
    </div>
  );
};

export default Verification;
