import React from 'react';
import TotalOrdersCircleChart from '../../../components/charts/TotalOrdersCircleChart';
import { useQuery } from 'react-query';
import { getOrdersCountInStatus } from '../../../stores/statistic';
import Loader from '../../../components/Loader';
import { EOrderStatus } from '../../../types/orders';

const TotalOrders = () => {
  const { data: orders, isLoading } = useQuery( 'orders_total_stat', getOrdersCountInStatus );

  return (
    <div className='column containerContent width-49'>
      { isLoading ?
        <Loader position={ 'static' }/>
        :
        <>
          <div className='column width-85 padding20'>
            <div className='titleDashboardPage mb-10'>Total Orders</div>
            <TotalOrdersCircleChart orders={ orders }/>
          </div>
          <div className='row border-top justify-between'>
            <div className='column align-center justify-center width-50 padding10'>
              <div className='text-down'>Cancelled Orders</div>
              <div
                className='text-red mt-5'>{ orders?.find( o => o.status === EOrderStatus.REFUNDED )?.status || 0 }</div>
            </div>
            <div className='column align-center justify-center border-left width-50 padding10'>
              <div className='text-down'>Completed Orders</div>
              <div
                className='text-green mt-5'>{ orders?.find( o => o.status === EOrderStatus.EXECUTED )?.status || 0 }</div>
            </div>
            <div className='column align-center justify-center border-left width-50 padding10'>
              <div className='text-down'>On Going Orders</div>
              <div
                className='text-orange mt-5'>{ orders?.find( o => o.status === EOrderStatus.PAID )?.status || 0 }</div>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default TotalOrders;
