import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { IQuestionerID } from '../../types/questionnaires';
import { getQuestionerID, saveQuestionnaire } from '../../stores/questionnaires';
import Loader from '../../components/Loader';
import QuestionnaireForm from './QuestionnaireForm';


const EditQuestionnaires = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: questionerID,
    isLoading
  } = useQuery<IQuestionerID>( [ 'questionerID' ], () => getQuestionerID( id ) );
  if ( isLoading ) return <Loader/>;

  const save = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    if ( id ) await saveQuestionnaire( id, data );
    navigate( '/questionnaires' );
  };

  return (
    <div className='editQuestionnaires'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit { questionerID?.name }</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/questionnaires' ) }>BACK TO LISTING
          </button>
        </div>
        <QuestionnaireForm item={ questionerID } save={ save }/>
      </div>
    </div>
  );
};

export default EditQuestionnaires;
