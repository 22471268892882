import React from 'react';
import Access from '../access/Access';
import { useNavigate } from 'react-router-dom';
import AsyncButton from '../AsyncButton';

interface IProps {
  onClick?: () => Promise<any>;
  group?: string;
}

const ButtonsCustomForm = ( { onClick, group }: IProps ) => {
  const navigate = useNavigate();

  return (
    <div className='row width-15 justify-between mb-20 mt-20'>
      <Access group={ group } action='update'>
        <AsyncButton className='btnEdit' onClick={ onClick }>Update</AsyncButton>
      </Access>
      <button className='btnEdit' type='button' onClick={ () => navigate( -1 ) }>Cancel
      </button>
    </div>
  );
};

export default ButtonsCustomForm;
