import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAttributeID, saveAttributes } from '../../stores/questionnaires';
import Loader from '../../components/Loader';
import AttributeForm from './AttributeForm';

const EditAttribute = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: attribute, isLoading } = useQuery( [ 'attribute', id ], () => getAttributeID( id ) );
  if ( isLoading ) return <Loader/>;

  const save = async ( form?: HTMLFormElement ) => {
    const data = new FormData( form );
    if ( id ) await saveAttributes( id, data );

    navigate( '/manageServices/attributes' );
  };

  return (
    <div className='editAttributes'>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit { attribute?.name?.en }</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/manageServices/attributes' ) }>BACK TO
            LISTING
          </button>
        </div>
        <AttributeForm save={ save } item={ attribute }/>
      </div>
    </div>
  );
};

export default EditAttribute;
