import React from 'react';
import '../styles/table.scss';
import { cn } from '../utils/utils';
import Paginate from './Paginate';

interface IProps<T> {
  items: T[];
  fields: { [ key: string ]: string } // это описание объекта, где описывается ключ firstname : и его значение 'Name'
  headFieldsClassName?: { [ key: string ]: string }
  bodyFieldsClassName?: { [ key: string ]: string }
  columnBody?: { [ key: string ]: ( item: T ) => any };
  count?: number;
}

const Table = <T extends object>(
  {
    items,
    fields,
    headFieldsClassName = {},
    bodyFieldsClassName = {},
    columnBody = {},
    count = 0
  }: IProps<T> ) => {
  return (
    <div className='containerTable width-100 mb-20'>
      <table className='table mb-30'>
        <thead className='thead'>
        <tr className='thInput'>
          <td className={ 'thInput' }>
            <input type='checkbox'/>
          </td>

          {/*//берем из объекта только ключи - Object.keys(fields)*/ }
          { Object.keys( fields ).map( field =>
            <td key={ field } className={ cn( 'thText', headFieldsClassName[ field ] ) }>{ fields[ field ] }</td>
          ) }
        </tr>
        </thead>
        <tbody>
        {
          //@ts-ignore
          items.map( item =>
            <tr
              //@ts-ignore
              key={ item.id }
              className={ cn( 'trText' ) }
            >
              <td className='thInput'>
                <input type='checkbox'/>
              </td>
              { Object.keys( fields ).map( field => {
                return (
                  //если мап наталкивается на переменную колумнбоди и видит в ней стандартный кей - записывает его, а
                  //если (?) он видит там дополнительное значение в виде айтем - он выполняет этот айтем, потому что это
                  //функция. в ином случае (:) читется инфа с сервера (item массив) и значения записываются согласно
                  //оговоренным полям вверху

                  <td key={ field } className={ 'thText ' + ( bodyFieldsClassName[ field ] || '' ) }>
                    { columnBody[ field ] ?
                      columnBody[ field ]( item )
                      :
                      //@ts-ignore
                      item[ field ]
                    }
                  </td>
                );
              } ) }
            </tr>
          ) }
        </tbody>
      </table>
      <div className='entries mb-20'>
        { count / 20 > 0 &&
          <Paginate
            count={ count }
            limit={ 20 }
          />
        }
      </div>
    </div>
  );
};

export default Table;
