import React, { useRef } from 'react';
import CustomForm from '../../components/form/CustomForm';
import ButtonsCustomForm from '../../components/form/ButtonsCustomForm';
import { IAdminID } from '../../types/admins';

interface IProps {
  item?: IAdminID;
  save: ( form?: HTMLFormElement ) => void;
}

const AdminForm = ( { item, save }: IProps ) => {
  const form = useRef<HTMLFormElement>( null );

  const click = async () => {
    await save( form.current || undefined );
  };

  return (
    <form ref={ form }>
      <CustomForm
        key={ item?.id }
        item={ item }
        fields={ {
          group: {
            name: 'Group',
            type: 'select',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          firstname: {
            name: 'First Name',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          username: {
            name: 'User Name',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          email: {
            name: 'E-mail',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          pass: {
            name: 'Password',
            type: 'input',
            props: {
              containerClassName: 'width-50',
              required: true
            }
          },
          status: {
            name: 'Status',
            type: 'checkbox',
            props: {
              containerClassName: 'width-50',
            }
          }
        } }
      />
      <ButtonsCustomForm onClick={ click } group={ 'admins' }/>
    </form>
  );
};

export default AdminForm;
