import React, { useMemo, useState } from 'react';
import '../styles/godsView.scss';
import Scrollbar from 'react-scrollbars-custom';
import { useQuery } from 'react-query';
import { IQuestioner } from '../types/questionnaires';
import Loader from '../components/Loader';
import { staticResource } from '../utils/resources';
import GMap, { IPoint } from '../components/map/GMap';
import { getListOrders } from '../stores/orders';
import { EOrderStatus, IOrders } from '../types/orders';
import { FilterParams } from '../stores/filter';
import { Rating } from 'react-simple-star-rating';

const GodsView = () => {
  const [ reset, setReset ] = useState( Date.now() );
  const [ refresh, setRefresh ] = useState( Date.now() );
  const [ filter, _setFilter ] = useState<FilterParams>( new FilterParams() );
  const setFilter = ( params: any ) => {
    _setFilter( new FilterParams( params ) );
  };

  const {
    data: orders,
    isLoading
  } = useQuery<IOrders>( [ 'orders', refresh, reset, filter.toString() ], () => getListOrders( filter ) );


  const points = useMemo( () => {
    const _points: IPoint[] = [];
    orders?.items.forEach( o => {
      o.questionnaires.forEach( q => {
        _points.push( {
          id: q.id,
          lat: q.lat,
          lon: q.lon,
          name: q.name
        } );
      } );
    } );

    return _points;
  }, [ orders?.items.length, refresh, reset ] );


  if ( isLoading ) return <Loader/>;

  const click = ( item: IQuestioner ) => () => {

  };

  return (
    <div className='godsView'>
      <div className='column containerContent padding20'>
        <div className='h2 titleAdmin mb-20'>Gods View</div>
        <div className='row justify-end gap-10 mb-20'>
          <button type="button" className='btnGodsView'
                  onClick={ () => setFilter( { status: EOrderStatus.PAID } ) }>Enroute to Pickup
          </button>
          <button type="button" className='btnGodsView'
                  onClick={ () => setFilter( { status: EOrderStatus.PAID, deliveryAddress: '' } ) }>Available to Pickup
          </button>
          <button type="button" className='btnGodsViewGreen' onClick={ () => setFilter( {} ) }>All</button>
        </div>
        <div className='d-flex align-center justify-end width-100 mb-20'>
          <div className='row justify-between width-15'>
            <button type="button" className='btnGodsViewBlue' onClick={ () => setReset( Date.now() ) }>Reset map
            </button>
            <button type="button" className='btnGodsViewBlue' onClick={ () => setRefresh( Date.now() ) }>Refresh
            </button>
          </div>
        </div>
        <div className='row justify-between'>
          <div className='column width-45 container'>
            <div className='greenTitle'>All</div>
            {/*<input type='text' className='input padding10 br-none' placeholder='search person'/>*/ }
            <Scrollbar className='scrollGodsView' style={ { width: '99.7%', height: '100%' } }>
              { orders?.items.map( item => {
                return item.questionnaires.map( q => {
                  return (
                    <div key={ item.id } className='row itemPerson align-center justify-start'>
                      <div className='imgPerson d-flex align-center justify-center mr-10'>
                        <img src={ staticResource( q.photo ) } className='width-100'/>
                        {/*<StatusCircle status={ q.status !== 0 }/>*/ }
                      </div>
                      <div className='column'>
                        <div className='textName'>{ q.name }</div>
                        <Rating
                          fillColor={ '#ff9811' }
                          emptyColor={ '#fff' }
                          size={ 25 }
                          initialValue={ q.rating }
                          readonly
                        />
                      </div>
                    </div>
                  );
                } );
              } ) }
            </Scrollbar>
          </div>
          <div className='width-55 container'>
            <div className='googleMaps'>
              <GMap points={ points }/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GodsView;
