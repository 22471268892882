import { apiGet, apiPost } from '../api';

export const getSettings = async () => {
  const response = await apiGet<any>( { url: '/settings' } );
  let settings: any = {};
  response.items?.forEach( ( item: any ) => {
    settings[ item.code ] = item.value;
  } );

  return settings;
};

export const saveSettings = async ( data: any ) => {
  return await apiPost<any>( {
    url: '/settings',
    postData: data
  } );
};
