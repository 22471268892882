import React from 'react';
import '../../styles/search.scss';
import { useFilterParams } from '../../stores/filter';
import SelectorStatus from '../selectors/SelectorStatus';

const GeoFilter = () => {
  const [ filter, changeFilter, clearFilter ] = useFilterParams();

  return (
    <div className='search column justify-center'>
      <div className='row justify-between align-center width-100'>
        <div className='row width-70 align-center justify-between'>
          <div className='searchTitle'>Search</div>

          <input
            type='text'
            className='input width-30'
            defaultValue={ filter.name }
            onChange={ changeFilter( 'name' ) }
          />

          <SelectorStatus/>

          <button type='button' className='btn' onClick={ clearFilter }>RESET</button>
        </div>
      </div>
    </div>
  );
};

export default GeoFilter;
