import React from 'react';
import { Link } from 'react-router-dom';
import { GoBell } from 'react-icons/go';

const ContactUs = () => {
  return (
    <div className='column containerContent width-49'>
      <div className='row justify-between width-90 padding20'>
        <div className='titleDashboardPage'>Contact Us Form Requests</div>
        <Link to="/support/contact_us" className='btnView link-button'>View all</Link>
      </div>
      <button className='d-flex width-90 padding20'>
        <div className='row justify-between align-center padding10 width-100 bName'>
          <div className='row'>
            <div className='circleDiv blue d-flex mr-10'>
              <GoBell className='iconCircle dark-blue'/>
            </div>
            <div className='column align-center justify-center'>
              <div className='text-up'>Jan*****</div>
              <div className='text-down'>s*******</div>
            </div>
          </div>
          <div className='d-flex text-up align-center justify-center'>131 Days ago</div>
        </div>
      </button>
    </div>
  );
};

export default ContactUs;
