import { ICustomer } from './customers';

export interface IWithdrawRequests {
  items: IWithdrawRequest[];
  count: number;
}

export interface IWithdrawRequest {
  id: number;
  customerId: string;
  balanceLogId: number;
  amount: number;
  comment: string;
  rejectComment: string;
  status: number;
  created_at: string;
  updated_at: string;
  customer: ICustomer;
}

export enum EWithdrawStatus {
  CREATED,
  CONFIRMED,
  REJECTED,
}
