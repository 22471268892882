import React, { useMemo, useRef } from 'react';
import { useStore } from 'effector-react';
import { $languages } from '../../stores/language';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getListPermissions } from '../../stores/permissions';
import { IRoleID } from '../../types/roles';
import { IPermissionGroup } from '../../types/permissions';
import Loader from '../../components/Loader';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import CustomForm from '../../components/form/CustomForm';
import Permission1 from './Permission1';
import ButtonsCustomForm from '../../components/form/ButtonsCustomForm';

interface IProps {
  role?: IRoleID;
  save: ( form?: HTMLFormElement ) => void;
  label: string;
}

const RoleForm = ( { role, save }: IProps ) => {
  const form = useRef<HTMLFormElement>( null );
  const languages = useStore( $languages );
  const navigate = useNavigate();

  const { data: permissions, isLoading: isLoadingPerm } = useQuery( [ 'permissions' ], () => getListPermissions() );

  const roleSelected = useMemo( () => {
    let _permissions: { [ groupName: string ]: { [ actionName: string ]: boolean } } = {};
    role?.permissions.map( p => {
      if ( !_permissions[ p.group ] ) _permissions[ p.group ] = {};
      _permissions[ p.group ][ p.action ] = true;
    } );
    return _permissions;
  }, [ role?.id ] );

  const permiss = useMemo( () => {
    const _permissions: { [ groupName: string ]: IPermissionGroup } = {};
    permissions?.items.forEach( permission => {
      if ( !_permissions[ permission.group ] ) {
        _permissions[ permission.group ] = {
          group: permission.group,
          actions: []
        };
      }
      _permissions[ permission.group ].actions.push( permission );
    } );

    return Object.values( _permissions );
  }, [ isLoadingPerm, role?.id ] );

  if ( isLoadingPerm ) return <Loader/>;

  const onSave = async () => {
    await save( form.current || undefined );
  };

  const selectAll = () => {
    form.current?.querySelectorAll( 'input' )?.forEach( e => {
      e.checked = true;
    } );
  };

  const unselectAll = () => {
    form.current?.querySelectorAll( 'input' )?.forEach( e => {
      e.checked = false;
    } );
  };

  return (
    <form className='editAdminGroups' onSubmit={ ( e ) => e.preventDefault() } ref={ form }>
      <div className='column containerContent padding20'>
        <div className='row titleAdmin justify-between mb-20'>
          <div className='h2'>Edit Admin Groups</div>
          <button className='btnAdd' type='button' onClick={ () => navigate( '/admin/roles' ) }>BACK TO LISTING</button>
        </div>
        <Tabs forceRenderTabPanel={ true }>
          <TabList>
            <div className='row absolut'>
              { languages.map( language =>
                <Tab key={ language.id }>{ language.name }</Tab>
              ) }
            </div>
          </TabList>
          <div className=' mt-40 mb-10 border-top'>
            { languages.map( language =>
              <TabPanel key={ language.id } className='row mt-10'>
                <div className='width-50'>
                  <CustomForm
                    key={ role?.id }
                    item={ role }
                    fields={ {
                      name: {
                        name: 'Group Name',
                        inputName: `name.${ language.language }`,
                        type: 'input',
                        props: {
                          required: true,
                          containerClassName: 'width-50'
                        }
                      },
                    } }/>
                </div>
              </TabPanel>
            ) }
          </div>
        </Tabs>
        <div className='column containerBlue mt-10'>
          <div className='row justify-between align-center topLineBlue'>
            <div className='titleBlue'>Group Permissions</div>
            <div className='row width-50 justify-end align-center'>
              <div className='row justify-center align-center'>
                <button type="button" className='blueButton' onClick={ selectAll }>Select All</button>
                <button type="button" className='redButton' onClick={ unselectAll }>De-Select All</button>
              </div>
            </div>
          </div>
          <div className='column padding20'>
            { permiss.map( group =>
              <Permission1 key={ group.group } selected={ roleSelected[ group.group ] } permissions={ group }/>
            ) }
          </div>
        </div>
        <ButtonsCustomForm onClick={ onSave } group={ 'roles' }/>
      </div>
    </form>
  );
};

export default RoleForm;
